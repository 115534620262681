import React from 'react';
import Icon from './arrow.svg';

const ArrowIcon = ({height='18', width='17', color='white', strokeColor='white', className='block'}) => {
	return <div className={className}>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 17 5"
			width={width}
			height={height}
			aria-hidden={true}
			enableBackground="new 0 0 17 18"
			xmlSpace="preserve"
		>
			<g>
				<path fill={color} d="M7.65322 1.23474C6.88806 0.710762 6.02655 1.09367 5.74316 2.08789C5.74316 2.08789 5.37476 3.35753 5.37476 5.07054C5.37476 6.78354 5.70349 7.91211 5.70349 7.91211C5.98121 8.90633 6.83705 9.28924 7.60221 8.76526L11.734 5.93712C12.4992 5.41314 12.4992 4.55327 11.734 4.0293L7.65322 1.23474Z"/>
				<path d="M7.65322 1.23474C6.88806 0.710762 6.02655 1.09367 5.74316 2.08789C5.74316 2.08789 5.37476 3.35753 5.37476 5.07054C5.37476 6.78354 5.70349 7.91211 5.70349 7.91211C5.98121 8.90633 6.83705 9.28924 7.60221 8.76526L11.734 5.93712C12.4992 5.41314 12.4992 4.55327 11.734 4.0293L7.65322 1.23474Z" fill={color} stroke={strokeColor} strokeMiterlimit="10" />
			</g>
		</svg>
	</div>
};

export default ArrowIcon;
