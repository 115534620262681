import React from "react";
import { GAME_MODES } from "@/lib/constants.mjs";
import LoadingPies from "./loader.svg";

const SmallLoader = ({ mode = GAME_MODES.daily}) => {
  const color =
    mode === GAME_MODES.endless ? "bg-darkness bg-opacity-80" : "bg-secondary bg-opacity-35";
  return (
    <div className={"flex flex-col items-center justify-center w-full h-full pb-4"}>
      <div className={`flex-col items-center justify-center`}>
        <div className={`p-4 rounded-full backdrop-blur ${color}`}>
          <LoadingPies
            data-testid="loading-pies"
            color={"#fff"}
            width={48}
            height={48}
            aria-label="Loading"
          />
        </div>
      </div>
    </div>
  );
};

export default SmallLoader;
