import React, {useState, forwardRef} from 'react';
import CloseButton from "@/components/common/buttons/CloseButton";
import Leaderboards from '@/components/live/Leaderboards'
import PanelModal from "../modals/PanelModal";

const LeaderboardModal = forwardRef(function LeaderboardModal(props, ref) {
	const [showModal, setShowModal] = useState(false);
	
	const handleClick = event => {
		event.preventDefault();
		setShowModal(true);
	};

	const onClose = () => {
		setShowModal(false);
		return true;
	};
	
	return (<>
		<a ref={ref} className={`${!showModal && 'hidden'} absolute 
		`} onClick={handleClick}>
			<PanelModal action={'leaderboard_view'} label={'Leaderboard View'} open={showModal} setOpen={onClose} bgTheme={'bg-[#50197D]'}>
				<Leaderboards />
			</PanelModal>
		</a>
	</>);
});

export default LeaderboardModal;
