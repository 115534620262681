import React from 'react';
import Fire from "@/components/icons/FireIcon";
import ArrowIcon from "@/components/icons/ArrowIconFlat";
import PlayerSvg from '../../public/player-silhouette.svg';
import Peep from 'react-peeps';

const PlayerAvatar = ({ avatar }) => {
    return (
        <Peep
            style={{
                width: 50,
                height: 50,
                justifyContent: 'center',
                alignSelf: 'center',
            }}
            circleStyle={{
                ...avatar?.circleStyle,
                width: 40,
                height: 40,
            }}
            accessory={avatar?.accessory}
            body={avatar?.body}
            face={avatar?.face}
            hair={avatar?.hair}
            facialHair={avatar?.facialHair}
            backgroundColor={avatar?.backgroundColor}
            viewBox={{ x: '90', y: '0', width: '1050', height: '1000' }}
            strokeColor='#000000'
        />
    )
}

export default PlayerAvatar;