import React, { useState, useEffect } from "react";
import Panel from "@/components/panels/Panel";
import Fire from "@/components/icons/FireIcon";
import ArrowIcon from "@/components/icons/ArrowIconFlat";
import { usePlayer } from "@/lib/usePlayer";
import PlayerAvatar from "../common/PlayerAvatar";
import { getRandomAvatar } from '@/components/avatar/constants.mjs';
import SmallLoader from "@/components/common/SmallLoader";

const Tabs = ({active, onChange}) => {
  const activeStyle = 'bg-staff bg-opacity-60 text-white p-1 w-full mx-auto';
  const inactiveStyle = 'text-white bg-playlist bg-opacity-10 hover:text-gray-300 dark:hover:text-gray-300 hover:cursor-pointer p-1 w-full mx-auto';

  const onClick = (e) => {
    onChange(e.target.value)
  }

  return (<>
    <div className="-mt-2 -mx-2 flex flex-row text-lg bg-black bg-opacity-20 font-gSemi text-center text-gray-500 dark:text-gray-400">
      <button onClick={onClick} value="daily" className={`rounded-tl-md ${active === 'daily' ? activeStyle : inactiveStyle}`}>Daily</button>
      <button onClick={onClick} value="weekly" className={`${active === 'weekly' ? activeStyle : inactiveStyle} `} >
        Weekly      
      </button>
      <button onClick={onClick} value="monthly" className={`rounded-tr-md ${active === 'monthly' ? activeStyle : inactiveStyle}`} >
        Monthly
      </button>
    </div>
  </>)
}

const TopThree = ({board, currentPlayer}) => {
  const topPlayers = board.slice(0, 3);
  return (
    <div className={`flex flex-row w-full`}>
      {topPlayers.map((item, index) =>
          <PlayerTile player={item} rank={index+1} key={index} currentPlayer={currentPlayer} />
      )}
    </div>
  )
}

const PlayerTile = ({player, rank, currentPlayer}) => {
  const { name, streak, score, avatar } = player;

  const style = name && name === currentPlayer?.username
    ? 'm-1 backdrop-blur-md backdrop-brightness-75 bg-amber-100 bg-opacity-90 text-primary p-2 rounded-md w-full flex flex-col justify-center font-gBold text-md' 
    : 'm-1 backdrop-blur-md backdrop-brightness-75 bg-white bg-opacity-90 text-primary p-2 rounded-md w-full flex flex-col justify-center font-gBold text-md';
  
    const rankText = rank === 1 ? '1st Place' : rank === 2 ? '2nd Place' : '3rd Place';
  // const rankIcon = rank === 1 ? '🥇' : rank === 2 ? '🥈' : '🥉';

  return (
    <div 
      className={`${style}`}>
      <div className={'mx-auto'}>{rankText}</div>
      <div className={'mx-auto icon flex flex-row'}>
        <PlayerAvatar avatar={avatar}/>
        <div>
          <div className='flex flex-row'>
            <ArrowIcon color="primary" strokeColor="primary" className="mt-[3px]" />
            <span className={'icon text-sm mt-1'}>{score}</span>
          </div>
          <div className='flex flex-row'>
            <Fire color="primary" className="m-[1px] mr-1" />
            <span className={'icon text-sm'}>{streak || 0}</span>
          </div>
        </div>
      </div>
      <div className={'text-xs text-center text-zinc-500 mx-auto my-auto'}>
        {!name ? 'no-name player' : name.split(' \#')[0]}
      </div>
    </div>
  )
}

const Leaderboard = ({board, limit=false, currentPlayer}) => {
  // is our current user in this leaderboard? - used for styling
  const userInBoard = board.find((item) => item.name === currentPlayer?.username);
  const index = board.indexOf(userInBoard);
  const isAbsentOrTopFive = index < 4;

  // add indexes, random avatar if needed
  board.forEach((item, index) => {
    item.rank = index+1;
    item.avatar = !!item.avatar ? item.avatar : getRandomAvatar()
  })

  const visibleRows = !limit ? board.slice(3,board.length) : isAbsentOrTopFive ? board.slice(3,8) : board.slice(index-2,index+3);
  return board?.length >= 1 ? (<>
    <TopThree board={board} />
    <div
      className={`rounded w-full flex flex-col font-gBold text-2xl max-h-[500px] overflow-y-scroll`}>
        {visibleRows.map((player, index) =>
          <PlayerRow player={player} key={index} currentPlayer={currentPlayer} />
        )}
    </div>
  </>) : (<div className={"mt-5"}>
      <SmallLoader />
  </div>)
}

const PlayerRow = ({player, currentPlayer}) => {
  const {name, streak, score, avatar, rank} = player;
  const style = name && name === currentPlayer?.username
    ? 'm-1 bg-sports rounded-full flex justify-center font-gBold text-base' 
    : 'm-1 backdrop-blur-md backdrop-brightness-75 bg-white/20 rounded-full flex justify-center font-gBold text-base';
  return (
    <div 
      className={style}>
        <div className={'my-auto'}>
          <PlayerAvatar avatar={avatar}/>
        </div>
        <div className={'text-left my-auto ml-1.5 w-[10%] scale-80'}>{rank}</div>
        <div className={'my-auto w-[75%] text-center text-primary scale-90'}>
          {!name ? 'no-name player' : name.split(' \#')[0]}
        </div>
        <div className={'my-auto w-[4rem] text-center scale-80'}>
          <Fire color="white" className="inline-block mr-1"/>
          {streak || 0}
        </div>
        <div className={'my-auto pr-2 w-[4rem] text-center scale-80'}>
          <ArrowIcon color="white" strokeColor={"white"} className="inline-block" />
          <div className="inline-block w-5 text-left">{score}</div>
        </div>
    </div>
  )
}

const Leaderboards = ({
  questionLeaderboard = [],
  limit = false,
  defaultBoard = 'weekly'
}) => {
  const { data: currentPlayer } = usePlayer();

  const [activeBoard, setActiveBoard] = useState(defaultBoard);
  const [stats, setStats] = useState(questionLeaderboard);

  const fetchBoard = async () => {
    let days = (activeBoard === 'daily')
        ? 0
        : (activeBoard === 'weekly')
          ? 6
          : (activeBoard === 'monthly')
            ? 29 : null
    const res = await fetch(`/api/leaderboards/${days}`);
    const {leaderboard} = await res.json();
    setStats(leaderboard);
    return leaderboard
  }

  useEffect(() => {
    fetchBoard()
  }, [activeBoard]);

  return (
    <div className={`bg-primary bg-opacity-50 rounded-lg inline-flex flex-col items-center w-full`}>
      <Panel className="!max-w-[100%] border-none bg-opacity-[33%]">
        <Tabs active={activeBoard} onChange={(v) => setActiveBoard(v)} />
        <div className={'flex flex-col rounded-lg relative items-center mt-1'}>
          <Leaderboard board={stats} limit={limit} currentPlayer={currentPlayer} />
        </div>
      </Panel>
    </div>
  )
};

export default Leaderboards;
