import React from 'react';

const Fire = ({height='15px', width='16px', className='', color='white'}) => {
	return (
    <div className={className}>
      <svg 		
        width={width}
        height={height} 
        viewBox="0 0 15 16" 
        xmlns="http://www.w3.org/2000/svg" 
        enableBackground="new 0 0 15 16" 
        xmlSpace="preserve">
      <g>
        <path fill={`${color}`} d="M14.2038 8.17674C13.7834 5.88905 12.5406 3.78846 11.5504 2.73663L11.5481 2.73355L11.545 2.73124C11.3594 2.54875 11.1023 2.52103 10.8974 2.59957C10.6918 2.67811 10.5201 2.87138 10.5186 3.13858C10.5186 3.14474 10.5186 3.1509 10.5186 3.15629C10.4916 3.77999 9.65464 3.97173 9.35279 3.42502C8.60666 2.07596 7.40544 0.803142 5.88776 0.0608536C5.48812 -0.135498 4.98608 0.167115 5.02304 0.62758C5.04768 1.85035 4.66113 2.85521 4.08132 3.82157C3.79026 4.30668 3.45068 4.781 3.09109 5.26842C3.00947 5.3793 2.92631 5.49018 2.84315 5.6026C2.55978 5.98376 2.26872 6.37569 1.98536 6.7861C1.32392 7.75632 0.776444 9.13386 0.751033 10.5668C0.725623 12.0075 1.22844 13.5044 2.66527 14.6825C3.67552 15.5111 4.96298 15.883 6.25351 15.9993C6.40674 16.0131 6.51069 15.8476 6.43446 15.7144L3.5246 10.6746C2.9856 9.7414 3.38215 8.70035 4.40549 8.36231C4.40549 8.36231 5.62364 7.9596 7.32767 7.9596C9.0317 7.9596 10.3931 8.40697 10.3931 8.40697C11.4126 8.75579 11.806 9.80454 11.2678 10.737L8.39413 15.7144L8.39182 15.7174C8.32021 15.8406 8.41877 15.9946 8.56046 15.98C8.82688 15.9531 9.09253 15.9153 9.35433 15.8653C10.2568 15.692 11.1207 15.3678 11.8646 14.7957C14.22 12.9862 14.6258 10.4729 14.2038 8.17674Z"/>
      </g>
      </svg>
    </div>
  );
};

export default Fire;
